/* CandidateAnalysis.css */
.candidate-analysis {
    max-width: 1200px; /* Adjust as necessary for large screen */
    margin: 0 auto;
    padding: 20px;
  }
  
  .analysis-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .description-section, .job-concepts-section, .questions-section, .fit-section {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 5px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  .footer {
    margin-top: 20px;
    text-align: center;
  }
  /* Add this CSS to your stylesheet or use a CSS-in-JS solution */

.analysis-heading {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  
  .analysis-job-title {
    color: #007bff;
    margin-right: 8px; /* Add spacing between "Job Title" and the title itself */
  }
  
  .analysis-for {
    color: #999; /* Adjust the color for "Analysis for" */
    margin-right: 8px;
  }
  
  .candidate-name {
    font-style: italic;
    color: #333; /* Adjust the color for the candidate name */
  }
  .number_question{
    width:200px
  }
  span.selectedTags {
    margin: 5px;
    display: inline-block;
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .user-queried-button {
    /* Additional styling for the button */
  }
  