  /* Reset default margin and padding */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Container for the tabs */
  .tabs-container {
    display: flex;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  /* Style for the tab buttons */
  .tab-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #f8f8f8;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px 8px 0 0;
  }

  /* Style for the active tab button */
  .tab-button.active {
    background-color: #007bff; /* Different color for the selected tab */
    color: #fff; /* Text color for the selected tab */
  }

  /* Style for the tab content */
  .tab-content {
    padding: 20px;
    display: none;
   
    background-color: #fff; /* Paper card background color */
    border: 1px solid #007bff; /* Paper card border */
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Paper card shadow */
  }

  /* Display the active tab content */
  .tab-content.active {
    display: block;
  }