/* SelectCandidate.css */
.select-candidate {
    max-width: 100%; /* or the width you prefer for a large screen */
    margin: 0 auto;
    padding: 20px;
  }
  
  .grid-container-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
   
  }
  .grid-container-3 {
    display: grid;
    grid-template-columns: 1fr 1fr .7fr;
    gap: 20px;
  
  }
  
  .job-section, .candidate-section {
    background: #f8f8f8;

    border-radius: 5px;
    height:400px;
  }
  .candidate-section table{
    height:400px;
    overflow: auto;
  } 
  .jobs-list, .Candidates-list{
    height:400px;
    overflow: auto;
  }
 .Candidates-list ul li{
   margin:4;
   padding:5px;
   border:1px solid rgb(189, 186, 186);
   height:60px;
  }
  .jobs-list ul li{
    margin:4;
    padding:5px;
    border:1px solid rgb(189, 186, 186);
   height:60px;
   }
  .new-button {
    margin-bottom: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #eaeaea;
  }
  
  .analysis-button {
    display: block;
    margin-top: 20px;
    /* Additional styling for the button */
  }
  .Candidate-info,.job-info{
    background: rgb(48, 123, 220);
    color:white;
    padding:5px
  }
  .upload-resume{
    background: rgb(152, 180, 217);
    
    text-align:center;
    padding:15px;
    height:60px;
  }
  .wrapped-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; /* Adjust as needed */
  }
  .dropzone {height:120px}