/* CandidateReport.css */
.candidate-report {
    padding: 20px;
  }
  
  .report-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .report-section {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .score {
    grid-column: 2;
    grid-row: 1 / 3; /* Score spans two rows */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    border-radius: 50%;
    height: 200px; /* Example size, you can adjust */
    width: 200px; /* Same as height to make it circular */
    text-align: center;
  }
  
  
  