/* GeneratedQuestions.css */
.generated-questions {
    padding: 20px;
  }
  
  .questions-table {
    display: flex;
    flex-direction: column;
  }
  
  .table-row {
    display: flex;
  }
  
  .table-cell {
    flex: 1; /* Assign equal width to all columns */
    padding: 10px;
   
  }
  
  .header .table-cell {
    font-weight: bold;
    background-color: #f4f4f4;
  }
  
  .rating {
    margin-top: 10px;
    font-style: italic;
  }

  .interviewgenius .container {
    display: grid;
    grid-template-columns: 1.2fr 1.6fr 1.2fr;
    gap: 20px;
    height:500px

  }
  
  .column {

    padding: 10px;
  }
  
  .questions {
    height: 500px; /* Adjust as needed */
    overflow-y: auto; /* Enables scrolling */
  }
  
  .question-item {
    cursor: pointer;
     max-width: 400px; /* Adjust the maximum width as needed */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .question-card {
  
    border-radius: 5px;
  }
  
  
  .question-item .selected {
    border: 1px solid blue; /* Highlight selected question */
  }
  
  /* Additional styling for headers and paragraphs can be added here */
  .feedback-sections {
    display: flex;
  }
  
  .feedback-section {
    flex: 1;
    margin-right: 20px; /* Adjust spacing between sections as needed */
  }

  .feedback-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  user-select: none; /* Prevents text selection */
  transition: background-color 0.3s ease;
}

.positive-tag {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.positive-tag.selected {
  background-color: #155724;
  color: #d4edda;
}

.negative-tag {
  background-color: #edd6d4;
  color: #ee6060;
  border: 1px solid #e6c3c3;
}

.negative-tag.selected {
  background-color: #e70f0f;
  color: #d4edda;
}
.overall-container p{height:80px;border: 1px solid rgb(215, 215, 215); overflow: auto;}
  
  